body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  background: #fff9f7;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: 'Chakra Petch', sans-serif;
  line-height: 1.8;
}

h1, h2, h3 {
  font-family: 'Orbitron', sans-serif;
}

.footer {
  font-family: "Poppins", sans-serif;
  background-color: #172b4d;
  color: #979797;
}

.sansita {
  font-family: "Sansita Swashed", cursive;
}

.font-sansita {
  font-family: "Sansita Swashed", cursive;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.icon-green {
  fill: #049c64;
  stroke: none;
}

/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
